<script>
export default {
  data() {
    return {
      selectedElementA: '',
      selectedElementB: '',
      elementA: ['Gold (Au)','Silver (Ag)','Copper (Cu)','Iron (Fe)','Zinc (Zn)','Aluminum (Al)'],
      elementB: {
        'Gold (Au)': ['Gold (Au)'],
        'Silver (Ag)': ['Gold (Au)', 'Silver (Ag)'],
        'Copper (Cu)': ['Gold (Au)','Silver (Ag)','Copper (Cu)'],
        'Iron (Fe)': ['Gold (Au)','Silver (Ag)','Copper (Cu)','Iron (Fe)'],
        'Zinc (Zn)': ['Gold (Au)','Silver (Ag)','Copper (Cu)','Iron (Fe)','Zinc (Zn)'],
        'Aluminum (Al)': ['Gold (Au)','Silver (Ag)','Copper (Cu)','Iron (Fe)','Zinc (Zn)','Aluminum (Al)'],
      },
      solutions: {
          'Gold (Au)': 'AuNO₃ (aq)',
          'Silver (Ag)': 'AgNO₃ (aq)',
          'Copper (Cu)': 'Cu(NO₃)₂ (aq)',
          'Iron (Fe)': 'Fe(NO₃)₂ (aq)',
          'Zinc (Zn)': 'Zn(NO₃)₂ (aq)',
          'Aluminum (Al)': 'Al(NO₃)₂ (aq)'
      },
      eValues: {
        'Gold (Au)': 197/3,
        'Silver (Ag)': 108/1,
        'Copper (Cu)': 63.5/2,
        'Iron (Fe)': 56/2,
        'Zinc (Zn)': 65/2,
        'Aluminum (Al)': 27/3
      },
      amps: 0.0, // initial amps
      minutes: 0,// initial min
      seconds: 0,// initial sec
      isLocked: false,//control parameter is lock or not
      remainMin: 0,
      remainSec: 0,
      dynamicMassA: 10.00, // 初始值 :dynamicMassA
      dynamicMassB: 10.00, // 初始值 :dynamicMassB
      targetMassA: 10.00,  // massA 的最终值
      targetMassB: 10.00,  // massB 的最终值
      countdownInterval: null, // save timer id
      intervalId: null,
      startlight: false,
      resetlight: false,
    };
  },
  computed: {
    filteredElements() {
      return this.elementB[this.selectedElementA] || [];
    },
    remainingTimeDisplay() {
      // 將剩餘時間的分和秒格式化為字符串
      // Format remaining minutes and seconds into a string
      const min = this.remainMin < 10 ? `0${this.remainMin}` : this.remainMin;
      const sec = this.remainSec < 10 ? `0${this.remainSec}` : this.remainSec;
      return `${min} : ${sec} `;
    },
    dynamicMassAFormatted() {
      return this.dynamicMassA.toFixed(2);
    },
    dynamicMassBFormatted() {
      return this.dynamicMassB.toFixed(2);
    },
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    updateElementB() {
      this.selectedElementB = '';
    },
    getImagePath(type, name1, name2) {
      if (type === 'elementA') {
        return `/elementA/${name1}.png`;
      } else if (type === 'elementB'){
        return `/elementB/${name1}.png`;
      } else if (type === 'solution') {
        const imagePathResult = `/solution/${name1 + name2}.png`;
        console.log(imagePathResult)
        return `/solution/${name1 + name2}.png`;
      }
    },
    getSolution() {
      if (this.solutions[this.selectedElementA]) {
        return this.solutions[this.selectedElementA];
      }
      return 'No solution';
    },
    adjustAMPS(increase) {
      if (increase) {
        this.amps = Math.min(30, this.amps + 0.5);
      } else {
        this.amps = Math.max(0, this.amps - 0.5);
      }
    },    
    adjustTime(increase, unit, maxMinutes) {
      if (unit === 'minutes') {
        if (increase) { // True:min add
          this.minutes = Math.min(maxMinutes, this.minutes + 1);
        } else { // False:min sub
          this.minutes = Math.max(0, this.minutes - 1);
        }
      } else if (unit === 'seconds') {
        if (increase) { // True:sec add
          if (this.seconds === 50) {
            this.seconds = 0;
            this.minutes += 1;
          } else {
            this.seconds += 10;
          }
        } else { // False:sec sub
          if (this.seconds === 0 && this.minutes >= 1) {
            this.seconds = 50;
            this.minutes -= 1;
          } else if(this.seconds === 0 && this.minutes === 0){
            this.seconds = Math.max(0, this.seconds - 10)
          } else if(this.seconds > 0 && this.minutes === 0){
            this.seconds = Math.max(0, this.seconds - 10)
          } else {
            this.seconds -= 10;
          }
        }
      }
    },
    startCountdown() {
      this.startlight = true;
      this.resetlight = false;
      if (this.minutes > 0 || this.seconds > 0) {
        this.isLocked = true;
        this.remainMin = this.minutes;
        this.remainSec = this.seconds;

        // 初始化 dynamicMassA 和 dynamicMassB 为 10
        this.dynamicMassA = 10;
        this.dynamicMassB = 10;

        const totalTimeInSeconds = this.remainMin * 60 + this.remainSec;

        this.targetMassA = 10 - ((this.eValues[this.selectedElementA]) * this.amps * totalTimeInSeconds) / 96500;
        this.targetMassB = 10 + ((this.eValues[this.selectedElementA]) * this.amps * totalTimeInSeconds) / 96500;

        // 计算每个时间段的增量，以平均分配时间
        const incrementA = (this.targetMassA - this.dynamicMassA) / totalTimeInSeconds;
        const incrementB = (this.targetMassB - this.dynamicMassB) / totalTimeInSeconds;

        // 使用 setInterval 啟動計時器，每20ms執行一次
        this.countdownInterval = setInterval(() => {
          if (this.remainMin > 0 || this.remainSec > 0) {
            this.dynamicMassA += incrementA;
            this.dynamicMassB += incrementB;

            if (this.remainSec === 0) {
              this.remainMin--;
              this.remainSec = 59;
            } else {
              this.remainSec--;
            }  
          } else {
            clearInterval(this.countdownInterval);
            this.dynamicMassA = this.targetMassA;
            this.dynamicMassB = this.targetMassB;
          }
        }, 20); // 1000 ms = 1 s // adjust this number for countdown speed        
      }
    },
    resetCountdown() {
      this.isLocked = false;
      this.remainMin = 0;
      this.remainSec = 0;
      this.dynamicMassA = 10;
      this.dynamicMassB = 10;
      clearInterval(this.countdownInterval);
      
      this.startlight = false;
      this.resetlight = true;
    },
  }
};

</script>

<template>
  <div class="container-experiment">
    <div class="section">
      <div class="coordinate">

          <select v-model="selectedElementA" class="selectedElementA" @change="updateElementB" :disabled="isLocked">
            <option value="">Choice element A</option>
            <option v-for="ele in elementA" :key="ele" :value="ele">{{ ele }}</option>
          </select>

          <select v-model="selectedElementB" :disabled="isLocked" class="selectedElementB">
            <option value="">Choice element B</option>
            <option v-for="ele in filteredElements" :key="ele" :value="ele">{{ ele }}</option>
          </select>

        <div class="solutionWord">Solution: {{ getSolution() }}</div>

        <img v-if="selectedElementA" class="eleAimg" :src="getImagePath('elementA', selectedElementA)" alt="Element A Image"/>
        <img v-if="selectedElementA && selectedElementB" class="solutionImg" :src="getImagePath('solution', selectedElementA, selectedElementB)" alt="Solution Image"/>

        <div class="volts">+ 6.00</div>
        <button class="amps-add" @click="adjustAMPS(true)" :disabled="isLocked" ></button>
        <button class="amps-sub" @click="adjustAMPS(false)" :disabled="isLocked"></button>
        <div class="amps">{{ amps }}</div>

        <button class="min-add" @click="adjustTime(true, 'minutes', 40)" :disabled="isLocked"></button>
        <button class="min-sub" @click="adjustTime(false, 'minutes')" :disabled="isLocked"></button>
        <button class="sec-add" @click="adjustTime(true, 'seconds')" :disabled="isLocked"></button>
        <button class="sec-sub" @click="adjustTime(false, 'seconds')" :disabled="isLocked" ></button>
        <div class="set-time">Initial Time : {{ minutes }} min. {{ seconds }} sec.</div>

        <div class="remain-time">{{ remainingTimeDisplay }}</div>

        <button class="btn-start" @click="startCountdown" :disabled="isLocked"></button>
        <button class="btn-reset" @click="resetCountdown" :disabled="!isLocked"></button>
        
        <img v-if="startlight" class="start-light" src="/button/NLRUNNINGBUTTON.png" alt="start-light" />
        <img v-if="resetlight" class="reset-light" src="/button/NLSTANDBYBUTTON.png" alt="reset-light" />
        
        <p class="pos-mass">mass<br>{{ dynamicMassAFormatted }} g</p>
        <p class="neg-mass">mass<br>{{ dynamicMassBFormatted }} g</p>

        <div class="sec-introduce">
          <p></p>
          <p>Instructions</p>
          <p>1. Select Positive and Negative Terminal Metal in the drop-down menu.</p>
          <p>2. Set parameters, including AMPS and time.</p>
          <p>3. Press the ON button and observe the mass.</p>
          <p>4. Press the OFF button, change the parameters, and perform the experiment again.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-experiment {
  display: grid;
  grid-template-columns: [col1] 100% [col2];
  grid-template-rows: [row1] 100% [row2];
  position: relative;
  overflow: auto;
  width: 1024px;
  height: auto;
  /* max-width and min-height Need to be multiple to the width and height of the background image */
  max-width: 1024px;
  min-height: 900px;
  margin: 0 auto;
}

.section {
  grid-column-start: col1;
  grid-column-end: col2;
  grid-row-start: row1;
  grid-row-end: row2;
  background-image: url('/public/WEBLAYOUT20.png');
  background-size: cover;
  background-position: center;
  height: 100%;
}
.coordinate {
  display: grid;
  grid-template-columns: repeat(200, 0.5%);
  grid-template-rows: repeat(400, 0.25%);
  position: relative;
  width: 100%;
  height: 100%;
}
.coordinate .selectedElementA{
  grid-column-start: 5;
  grid-column-end: 45;
  grid-row-start: 20;
  grid-row-end: 32;
  border-radius: 5px;
  color:aliceblue;
  background-color: #236a45;
}
.coordinate .selectedElementB{
  grid-column-start: 5;
  grid-column-end: 45;
  grid-row-start: 40;
  grid-row-end: 52;
  border-radius: 5px;
  color:aliceblue;
  background-color: #236a45;
}
.coordinate .solutionWord{
  grid-column-start: 5;
  grid-column-end: 45;
  grid-row-start: 60;
  grid-row-end: 72;
  border-radius: 5px;
  color:aliceblue;
  background-color: #236a45;
}
.coordinate .set-time {
  grid-column-start: 5;
  grid-column-end: 45;
  grid-row-start: 80;
  grid-row-end: 92;
  border-radius: 5px;
  color:aliceblue;
  background-color: #236a45;
}
.coordinate .eleAimg{
  grid-column-start: 141;
  grid-column-end: 155;
  grid-row-start: 77;
  /* grid-row-end: 252; */
  width: 131%;
  height: auto;
  z-index: 2;
}
.coordinate .eleB-img{
  /* grid-column-start: 160;
  grid-column-end: 180;
  grid-row-start: 80;
  grid-row-end: 96; */
  grid-column-start: 100;
  grid-column-end: 120;
  grid-row-start: 130;
  grid-row-end: 146;
  width: 50%;
  height: auto;
  z-index: 3;
}
.coordinate .solutionImg{
  grid-column-start: 140;
  grid-column-end: 198;
  grid-row-start: 77;
  grid-row-end: 200;
  width: 98%;
  height: auto;
  justify-items: center;
  align-items:   center;
  z-index: 4;
}
.coordinate .volts {
  grid-column-start: 80;
  grid-column-end: 92;
  grid-row-start: 68;
  grid-row-end: 80;
  transform: rotate3d(-1,0,1,-9deg);
  /* background-color: #236a45; */
}
.coordinate .amps-sub {
  grid-column-start: 80;
  grid-column-end: 86;
  grid-row-start: 105;
  grid-row-end: 113;
  /* transform: rotate3d(-1,0,1,-6deg); */
}
.coordinate .amps-sub:hover {
  background-image: url('/public/button/NL-button.png');
  background-size: 95%;
  background-position: center center;
}
.coordinate .amps-add {
  grid-column-start: 87;
  grid-column-end: 92;
  grid-row-start: 103;
  grid-row-end: 111;
  /* transform: rotate3d(-1,0,1,-9deg); */
}
.coordinate .amps-add:hover {
  background-image: url('/public/button/NL+button.png');
  background-size: 110%;
  background-position: center center;
}
.coordinate .amps {
  grid-column-start: 83;
  grid-column-end: 89;
  grid-row-start: 87;
  grid-row-end: 100;
  transform: rotate3d(-1,0,1,-7deg);
  /* background-color: #236a45; */
}
.coordinate .min-add {
  grid-column-start: 97;
  grid-column-end: 105;
  grid-row-start: 84;
  grid-row-end: 97;
  /* transform: rotate3d(-1,0,1,-9deg); */
}
.coordinate .min-add:hover{
  background-image: url('/public/button/NL+1MINVER3.png');
  background-size: 95%;
  background-position: center center;
}
.coordinate .min-sub {
  grid-column-start: 109;
  grid-column-end: 115;
  grid-row-start: 80;
  grid-row-end: 92;
  /* transform: rotate3d(-1,0,1,-9deg); */
}
.coordinate .min-sub:hover {
  background-image: url('/public/button/NL-1MIN.jpg');
  background-size: 120%;
  background-position: center center;
}
.coordinate .sec-add {
  grid-column-start: 98;
  grid-column-end: 105;
  grid-row-start: 97;
  grid-row-end: 109;
  /* transform: rotate3d(-1,0,1,-9deg); */
}
.coordinate .sec-add:hover {
  background-image: url('/public/button/NL+10sec.png');
  background-size: 95%;
  background-position: center center;
}
.coordinate .sec-sub {
  grid-column-start: 109;
  grid-column-end: 115;
  grid-row-start: 92;
  grid-row-end: 105;
  /* transform: rotate3d(-1,0,1,-9deg); */
}
.coordinate .sec-sub:hover {
  background-image: url('/public/button/NL-10sec.png');
  background-size: 120%;
  background-position: center center;
}
.coordinate .remain-time{
  grid-column-start: 100;
  grid-column-end: 113;
  grid-row-start: 64;
  grid-row-end: 76;
  transform: rotate3d(-1,0,1,-9deg);
  /* background-color: #236a45; */
}
.coordinate .btn-start {
  grid-column-start: 64;
  grid-column-end: 68;
  grid-row-start: 89;
  grid-row-end: 100;
  transform: rotate3d(-1,0,1,-9deg);
}
.coordinate .btn-start:hover {
  background-image: url('/public/button/NLonbutton.png');
  background-size: 110%;
  background-position: center center;
  transform: rotate3d(-1,0,1,3deg);
}
.coordinate .btn-reset {
  grid-column-start: 71;
  grid-column-end: 76;
  grid-row-start: 87;
  grid-row-end: 97;
  transform: rotate3d(-1,0,1,-6deg);
}
.coordinate .btn-reset:hover{
  background-image: url('/public/button/NLoffbutton.png');
  background-size: 110%;
  background-position: center center;
  transform: rotate3d(-1,0,1,3deg);
}
.coordinate .start-light {
  grid-column-start: 73;
  grid-column-end: 79;
  grid-row-start: 76;
  grid-row-end: 81;
  width: 50%;
  height: auto;
}
.coordinate .reset-light {

  grid-column-start: 72;
  grid-column-end: 79;
  grid-row-start: 68;
  grid-row-end: 75;
  width: 50%;
  height: auto;
}
.coordinate .pos-mass{
  grid-column-start: 140;
  grid-column-end: 160;
  grid-row-start: 52;
  grid-row-end: 80;
  border-radius: 5px;
  background-color: aliceblue;
}
.coordinate .neg-mass{
  grid-column-start: 170;
  grid-column-end: 190;
  grid-row-start: 52;
  grid-row-end: 80;
  border-radius: 5px;
  background-color: aliceblue;
}
.coordinate .sec-introduce{
  grid-column-start: 10;
  grid-column-end: 190;
  grid-row-start: 252;
  grid-row-end: 360;
  border-radius: 5px;
  color:aliceblue;
  background-color: #236a45;
}
</style>
